<nav class="navbar main">
	<h1>
		Angular Blog
		<small
			>(
			<a class="redirect" [routerLink]="['/admin']">Login</a>
			)</small
		>
	</h1>
</nav>

<div class="container">
	<router-outlet></router-outlet>
</div>
